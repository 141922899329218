@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@400;500;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: 'Tajawal', sans-serif;
}

[dir='rtl'] {
  /* Add your RTL-specific styles here */
  /* For example, you can add text-align: right; to align text to the right */
  text-align: right;
}

.links::-webkit-scrollbar {
  display: none;
}

.tox-statusbar {
  display: none !important;
}

.CodeMirror-sizer {
  text-align: left !important;
}